<div *ngIf="session">

 <!--  <div class="landscape-overlay" *ngIf="ots.userConfig.portraitMsg">
    <span>{{ots.userConfig.portraitMsg}}</span>
  </div> -->

  <div id="screen-{{ots.userData.role}}" *ngIf="currentState != 'disconnected' && currentState != 'disconnecting'; else disconnected">
    <div *ngIf="!desconectado">
      <div id="textos-inicio">
        <h1>Bienvenido {{ots.userData.name}} </h1>
        <div *ngIf="ots.hasPermission('ManageArchives')" style="display: none;">
          <span>You can Manage Archives</span>
          <br>
          <button (click)="startArchive()">Start recording</button>
          <button (click)="stopArchive()">Stop recording</button>
        </div>
  
        <h2 *ngIf="streams.length == 0">{{ots.userConfig.emptyMsg||'No hay nadie más en la llamada'}}</h2>
        <h2 *ngIf="getSpectatorNames()">{{getSpectatorNames()}} está espectando la llamada</h2>
      </div>
      <div *ngIf="ots.hasPermission('CreateStreams')">
        <app-publisher *ngIf="doPublish" [session]="session"></app-publisher>
      </div>
      <div id="subscribers" *ngIf="streams && streams.length">
        <app-subscriber *ngFor="let stream of streams; let i=index;" [className]="'role_'+stream.role" [ngClass]="{'user': screen && stream.strm.videoType === 'camera', 'screen': screen && stream.strm.videoType === 'screen'}" [stream]="stream.strm" [session]="session"></app-subscriber>
      </div>
      <div [style.display]="chatIsView ? 'block' : 'none'">
        <app-chat></app-chat>
      </div>
    </div>
    <div *ngIf="desconectado">
      <div id="textos-inicio">
        <h2>Ha abandonado la llamada</h2>
      </div>
    </div>
  </div>
  <ng-template #disconnected>
    <div id="textos-inicio">
      <h2>Ha abandonado la llamada</h2>
    </div>
  </ng-template>

  <button *ngIf="checkFullscreen(); else fullscreenOff" class="fullscreen-btn controls-button fullscreen-btn-active" (click)="toggleFullscreen()" >
    <fa-icon [icon]="faCompress"></fa-icon>
  </button>
  <ng-template #fullscreenOff>
    <button class="fullscreen-btn controls-button " (click)="toggleFullscreen()">
      <fa-icon [icon]="faExpand"></fa-icon>
    </button>
  </ng-template>
</div >

<div *ngIf="!session">
  <h1>Loading...</h1>
</div>

<br><br><br>