<div #subscriberDiv></div>
<div class="subscriber-controls" *ngIf="ots.hasPermission('ManageStreams')">

    <ng-container >
        
        <!-- <button (click)="toggleAudio()" *ngIf="subscriber && subscriber.stream && subscriber.stream.hasAudio; else elseAudio" class="mute controls-button">
            <fa-icon [icon]="faMicrophone"></fa-icon>
        </button>
        <ng-template #elseAudio>
            <button (click)="toggleAudio()" class="controls-button btn-red mute"><fa-icon [icon]="faMicrophoneSlash"></fa-icon></button>
        </ng-template> -->

    </ng-container>

</div>