import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DatashareService {

  // Mensaje de error
  private errorMessage = new BehaviorSubject('¡Vaya! Algo salió mal, inténtalo de nuevo mas tarde');
  shareErrorMessage = this.errorMessage.asObservable();

  // Compartir pantalla
  private sharingScreen = new BehaviorSubject(false);
  shareSharingScreen = this.sharingScreen.asObservable();
  private someoneSharing = new BehaviorSubject(false);
  shareSomeoneSharing = this.someoneSharing.asObservable();

  // Chat
  private chatShowing = new BehaviorSubject(false);
  shareChatShowing = this.chatShowing.asObservable();
  private unreadMessages = new BehaviorSubject(0);
  shareUnreadMessages = this.unreadMessages.asObservable();
  private chatMessages = new BehaviorSubject([]);
  _chatMessages = this.chatMessages.asObservable();

  constructor(private route: Router) { 

  }

  /**
   * Conmuta la visibilidad del chat
   *
   * @param {boolean} status - Estado del chat deseado, true = abierto, false = cerrado
   * @memberof DatashareService
   */
   nextChatShowing(status: boolean) {
    this.chatShowing.next(status);
  }

  /**
   * Establece el número de mensajes sin leer
   *
   * @param {number} n - Número de mensajes sin leer
   * @memberof DatashareService
   */
  nextUnreadMessages(n: number) {
    this.unreadMessages.next(n);
  }

  /**
   * Agrega un mensaje a la pila de mensajes de chat existente.
   * 
   * @param message {Object} - nuevo mensaje para ser agregado a la pila
   */
  newMessaggeChat(message: Object) {
    const messages = this.chatMessages.getValue()
    messages.push(message);
    this.chatMessages.next(messages);
  }

  /**
   * Sustituye la pila de mensajes actual por la una nueva
   *
   * @param {Object[]} messages
   * @memberof DatashareService
   */
  setMessagesChat(messages: Object[]) {
    this.chatMessages.next(messages);
  }

  
  /**
   * Redirige a otra pantalla dentro de la aplicación
   *
   * @param {String} url - Ruta a la que se redirigirá
   * @param {Number} delay - Milisegundos para ejecutar la redirección
   */
   redirect(url, delay = 0) {
    if (delay > 0) {
      setTimeout(() => {
        this.route.navigateByUrl(url);
      }, delay);
    } else {
      this.route.navigateByUrl(url);
    }
  }
  
}
