import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { DatashareService } from '../../../services/datashare.service';
import { OpentokService } from '../../../opentok.service';
import { FileUploadService } from '../../../services/file-upload.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

  @ViewChild('scroll') private scroll: ElementRef;
  @ViewChild('file') private inputfile: ElementRef;

  chatIsView = true;
  messageData: Object;
  dragOver: boolean = false;

  messages: any[] = [];
  message: any[] = [];
  file: any;
  textoBotonEnviar = 'attach_file';

  signalHandler;
  unreadMsg = 0;
  name = '';

  p1=''
  p2=''

  subscriptions: Subscription[] = [];

  constructor(
    private ots: OpentokService, 
    private data: DatashareService,
    private uploadFile: FileUploadService,) { 
   
  }

  // SE ENVÍA POR EL SOCKET DE TOKBOX, SE RECIBE EL EVENTO Y SE PREPARA, SE OBTIENEN LOS DATOS DEL OBSERVABLE
  // SE AÑADE EL ÚLTIMO MENSAJE Y SE ENVÍA EL NUEVO ARRAY POR LA SUBSCRIPCIÓN
  ngOnDestroy(): void {
    //this.ots._session.off('signal', this.signalHandler);

    // Close all subscriptions
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  ngOnInit(): void {
    // Enable all subscriptions method
    this.enableSubscriptions()

    // On receive a new chat message
    this.signalHandler = event => {
      const eventType = event.type.split('signal:')[1];

      switch (eventType) {
        case 'msgchat':
          this.chatWrite(event);
          break;

        case 'chatMessages':
          //this.chatMessages(event)
          break;

        case 'chatMessagesFetch':
          this.chatMessagesFetch(event)
          break;

          default:
            console.warn(`Evento no controlado por el componente de chat: ${event.type}`);
            break;
      }

    };

    this.ots.session.on('signal', this.signalHandler);

  
    this.name = this.ots.userData.name;
    console.log(this.ots);
    // Force screen reader to read this message
    setTimeout(() => {
      this.p1 = `Hola,`
      this.p2 = `¡Disculpa la espera! Si lo deseas utiliza este chat para anotar todo lo relativo a la consulta técnica para que nada se te olvide`
    },100)
  }


    /**
   * Subscripciones a las variables compartidas a partir del servicio datashare
   *
   * @memberof ChatComponent
   */
     enableSubscriptions(): void {
      // Sync status via service
      this.subscriptions.push(this.data.shareChatShowing.subscribe(status => {
       this.chatIsView = status;
       if (status) {
         this.data.nextUnreadMessages(0);
         setTimeout(() => {
           this.scroll.nativeElement.scrollTop = this.scroll.nativeElement.scrollHeight;
         }, 500);
       }
     }))
 
     // Sync data messages
     this.subscriptions.push(this.data._chatMessages.subscribe(messages => {
       this.messages = messages;
     }))
 
     // When connected to session request chat message history to another user
     /* this.subscriptions.push(this.ots._connected.subscribe(connected => {
       if (connected) {
           // Request the messages of the chat to the other user
           this.requestMessages();
       }
     })) */

     if (this.ots.session) {
      if (this.ots.session['isConnected']()) {
        this.requestMessages();
      }
      
    }
 
 
     // Subscribe to unread messages
     this.subscriptions.push(this.data.shareUnreadMessages.subscribe(n => this.unreadMsg = n))
   }

   /**
   * Manda una petición para obtener el histórico de chat a otros usuarios de la sala.
   *
   * @memberof ChatComponent
   */
  requestMessages() {

    this.ots.session.signal({
      type: 'chatMessagesFetch',
    }, err => {
      if (err) { console.log(err); }
    });
  
  }
   /**
   * Añade el mensaje recibido a la pila de mensajes, mostrándolo por pantalla o mostrando una notificación si no está visible
   *
   * @param {*} event - El evento signal recibido desde OpenTok
   * @memberof ChatComponent
   */
    chatWrite(event) {
      const data = JSON.parse(event.data);
  
    //  Generamos el mensaje
      const message = this.generateMessage(data)
      this.data.newMessaggeChat(message);
  
      // Diferenciar si el chat está abierto o no
      if (!this.chatIsView) {
        this.chatDisabled(data);
      } else {
        //this.cookieService.set('lastMessageRead', new Date().getTime().toString())
  
        setTimeout(() => {
          try {
            this.scroll.nativeElement.scrollTop = this.scroll.nativeElement.scrollHeight;
          } catch (err) { }
        }, 250);
      }
    }
  
    private generateMessage(data) {
      let signedUrl = null;
      let extension = null;
      let name = null;
  
     //  Si recibimos un fichero, establecemos los datos relativos al mismo
      if (data.type === 'file') {
        signedUrl = data.signedUrl;
        extension = data.extension;
        name = data.name;
  
        const imgExt = ['jpg', 'jpeg', 'png', 'gif'];
        if (imgExt.indexOf(extension) >= 0) { data.type = 'image'; }
  
      }
  
    //  Guardamos en el array de los mensajes los datos
      return  {
        msg: data.msg,
        username: data.username,
        type: data.type,
        signedUrl: signedUrl,
        extension: extension,
        name: name,
        //date: moment(data.date).format('H:mm'),
        dateRAW: new Date().getTime(),
        role: data.role,
        sameUser: data.role === this.ots.sessionData.Session.UserData.role ? true : false
      }
  
    }
  
    /**
     * Guarda en una variable local los mensajes recibidos desde otro usuario.
     *
     * @param {*} event
     * @memberof ChatComponent
     */
    private chatMessages(event) {
      const data =  JSON.parse(event.data);
      console.log({data});
  
      // Set the received messages
      data.messages.forEach(msg => { msg.sameUser = (msg.username === this.ots.userData.name); });
      this.messages = data.messages;
  
      // Update the messages in the service
      this.data.setMessagesChat(data.messages)
  
      if (!this.chatIsView) {
        //this.handleUnreadMessages();
      }
    }
    
    /**
     * Envía la lista de mensajes a otro usuario 
     *
     * @private
     * @param {*} event
     * @memberof ChatComponent
     */
    private chatMessagesFetch(event) {
      //const connections = this.ots.connections.getValue();
      //const con = connections.filter(e => e.name !== this.ots.userData.name).filter(e => e.conn === event.from).map(e => e.conn);
      //if (con.length > 0 && this.messages.length > 0) {
        this.ots.session.signal({
          to: event.from,
          type: 'chatMessages',
          data: JSON.stringify({
            userName: this.ots.sessionData.Session.UserData.name,
            role: this.ots.sessionData.Session.Permissions.TokboxRole,
            messages: this.messages
          })
        }, err => {
          if (err) { console.log(err); }
        });
      //}
    }
  
    /**
     * Procesa un mensaje cuando el chat está cerrado
     *
     * @param {*} data - Objeto con los datos relativos al mensaje
     * @memberof ChatComponent
     */
    chatDisabled(data) {
  
        let key = null;
        let locale = null;
  
        // si es fichero, le agrega los datos
        // if (data.type === 'file') {
        //   key = data.file.key;
        //   locale = data.file.Location;
        // }
  
        const msg = data.type === 'text' ? data.msg : 'Ha enviado un archivo';
        //this.snackbar.open(`${data.username}: ${msg}`, null, {duration: 5000, panelClass: 'float-message', horizontalPosition: 'right', politeness: 'off'});
        this.unreadMsg += 1;
        this.data.nextUnreadMessages(this.unreadMsg);
    }
  
  
    /**
     * Esconde el elemento del chat y actualiza el estado a los demás componentes
     *
     * @memberof ChatComponent
     */
    disableChat() {
      this.chatIsView = !this.chatIsView;
      this.data.nextChatShowing(this.chatIsView);
    }

    /**
   * Sube el fichero seleccionado al servidor mediante el servicio de subida de ficheros
   *
   * @param {*} $event - Evento lanzado por el elemento HTML al cambiar el contenido
   * @memberof ChatComponent
   */


    /**
   * Gestiona un fichero tras ser subido al servidor.
   *
   * @param {Object} response - Objeto con la respuesta recibido por el servidor
   * @memberof ChatComponent
   */
     processResponse(response) {
      if (response.Success !== undefined && response.Success === true) {
  
        //  Creamos el objeto del fichero
        this.file = {
          type: 'file',
          name: response.FileName,
          extension: response.FileExt,
          signedUrl: response.SignedUrl,
          username: this.ots.sessionData.Session.UserData.name,
          role: this.ots.sessionData.Session.UserData.role,
          msg: ''
        };
  
        //  Enviamos el mensaje
        this.sendMessage('msgchat', true);
  
      } else if (response.Success !== undefined && response.Success !== true) {
  
        console.log(response.Error);
  
      } else {
        console.log('NOKNER');
      }
  
    }

    /**
   * Obtiene una lista de mensajes y define los no leidos en base a la fecha del último mensaje leido
   *
   * @param {*} messages
   * @memberof ChatComponent
   */
  handleUnreadMessages() {
    //const lastMessageDate = parseInt(this.cookieService.get('lastMessageRead'))

     // Emit to service the unread messages
     //this.unreadMsg = this.messages.filter(m => m.dateRAW > lastMessageDate).length
     //this.data.nextUnreadMessages(this.unreadMsg);

     // Notify via snackbar to the user
     /* if (this.unreadMsg > 0) {
      this.snackbar.open(`Tiene ${this.unreadMsg} mensajes sin leer`, null,
      {duration: 50000, panelClass: 'float-message', horizontalPosition: 'right', politeness: 'assertive'});
     } */

    // Set new unred date messages
    //this.cookieService.set('lastMessageRead', new Date().getTime().toString())
  }

  /**
   * Prepara el texto del mensaje y añade los metadatos necesarios
   *
   * @param {ElementRef} textarea - Elemento Textarea del que tomar los datos
   * @memberof ChatComponent
   */
   prepareMessage(textarea) {

    const text = textarea.value.trim();

    if (text !== '') {

        this.messageData = {
            type: 'text',
            msg: text,
            username: this.ots.sessionData.Session.UserData.name,
            role: this.ots.sessionData.Session.UserData.role,
            date: ''//moment()
        };
        this.sendMessage('msgchat', false, textarea);
    }

  }

  /**
   * Envía el mensaje localmente a través del socket
   *
   * @param {string} type - Tipo de mensaje
   * @param {boolean} file - Indicador de si el mensaje es un fichero
   * @param {*} [textarea=false] - Elemento textarea, si es un fichero será un booleano definido en false
   * @memberof ChatComponent
   */
  sendMessage(type: string, file: boolean, textarea: any = false) {

    this.ots.session.signal({
        type: type,
        data: file ? JSON.stringify(this.file) : JSON.stringify(this.messageData),
    }, err => err && console.error(err));

    if (textarea) { textarea.value = ''; }

    if (file) { this.textoBotonEnviar = 'attach_file'; }

  }

}
