import { Injectable } from '@angular/core';

import * as OT from '@opentok/client';
import { environment } from '../environments/environment';
import config from '../config';

@Injectable()
export class OpentokService {

  session: OT.Session;
  token: string;
  sess: string = '';

  sessionData: any;
  recordingSession : boolean;

  public get userConfig():any {
    return this.sessionData.Session.Permissions.Config;
  }
  public get userData():any {
    return this.sessionData.Session.UserData;
  }

  constructor() { }

  getOT() {
    return OT;
  }

  initSession(sess:string) : Promise<OT.Session> {
    this.sess = sess;
    console.log('asdfsa',this.sess);
    if (config.API_KEY && config.TOKEN && config.SESSION_ID) { // Legacy only
      this.session = this.getOT().initSession(config.API_KEY, config.SESSION_ID);
      this.token = config.TOKEN;
      return Promise.resolve(this.session);
    } else {
      return fetch(config.API_VIDEO_SERVER + '/participants/' + sess + '/getSession')
        .then((data) => data.json())
        .then((json) => {
          this.sessionData = json;
          this.sessionData.Session.Hash = sess;
          this.sessionData.HashSession = json.Session.HashSession;
          this.session = this.getOT().initSession(json.Session.ApiKey, json.Session.Id);
          this.token = json.Session.Token;
          this.recordingSession = false;
          return this.session;
        }
      );
    }
  }

  public startArchive() : Promise<boolean> {
    this.recordingSession = true;
    return fetch(config.API_VIDEO_SERVER + '/participants/' + this.sessionData.Session.Hash + '/startArchive', {
      method: 'POST',
      body: JSON.stringify({Type:"video"})
    }).then((data) => data.json())
      .then((json) => {
        return json.Success;
      }
    ).catch(() => false);
  }

  public stopArchive() : Promise<boolean> {
    this.recordingSession = false;
    return fetch(config.API_VIDEO_SERVER + '/participants/' + this.sessionData.Session.Hash + '/stopArchive')
      .then((data) => data.json())
      .then((json) => {
        return json.Success;
      }
    ).catch(() => false);
  }

  public submitImgData(img:string) : Promise<boolean> {
    return fetch(config.API_VIDEO_SERVER + '/participants/' + this.sessionData.Session.Hash + '/uploadImage', {
      method: 'POST',
      body: JSON.stringify({Type:"image/png;base64", Data: img})
    }).then((data) => data.json())
      .then((json) => {
        return json.Success;
      }
    ).catch(() => false);
  }

  public submitLocationData(data:{Lat:number,Long:number,Accuracy:number}) : Promise<boolean> {
    return fetch(config.API_VIDEO_SERVER + '/participants/' + this.sessionData.Session.Hash + '/uploadLocation', {
      method: 'POST',
      body: JSON.stringify({Location:data})
    }).then((data) => data.json())
      .then((json) => {
        return json.Success;
      }
    ).catch(() => false);
  }

  connect() {
    return new Promise((resolve, reject) => {
      this.session.connect(this.token, (err) => {
        if (err) {
          reject(err);
        } else {
          resolve(this.session);
        }
      });
    });
  }

  hasPermission(perm:string) {
    return this.sessionData.Session.Permissions.Grant.includes(perm);
  }

  playAudio(sound: string) {
    let audio = new Audio();
    audio.src = "/assets/audio/"+sound+".mp3";
    audio.load();
    audio.play();
  }

  public disableSession(): Promise<boolean> {

    let headers = {
      'Api-Token': environment.TOKEN
    };
    return fetch(environment.API_VIDEO_SERVER + '/session/' + this.sessionData.HashSession + '/disableSession', {
      headers,
      method: 'POST'/* ,
      body: JSON.stringify({}) */
    }).then((data) => data.json())
      .then((json) => {
        return json.Success;
      }
    ).catch(() => false);
  }

}
