import { Component, ElementRef, AfterViewInit, ViewChild, Input } from '@angular/core';
import * as OT from '@opentok/client';
import { OpentokService } from '../../../opentok.service';


import { faCamera, faVideo, faMicrophone, faMicrophoneSlash, faSyncAlt, faStethoscope, faCog} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-subscriber',
  templateUrl: './subscriber.component.html',
  styleUrls: ['./subscriber.component.css']
})

export class SubscriberComponent implements AfterViewInit {
  @ViewChild('subscriberDiv', { static:true }) subscriberDiv: ElementRef;
  @Input() session: OT.Session;
  @Input() stream: OT.Stream;

  subscriber: OT.Subscriber;

  faCamera = faCamera;
  faVideo = faVideo;
  faMicrophone = faMicrophone;
  faMicrophoneSlash = faMicrophoneSlash;
  faSyncAlt = faSyncAlt;
  faStethoscope = faStethoscope;
  faCog = faCog;

  constructor(public ots: OpentokService) { }

  ngAfterViewInit() {
    this.subscriber = this.session.subscribe(this.stream, this.subscriberDiv.nativeElement, {
      fitMode: 'contain',
      style: {buttonDisplayMode: 'off'}
    }, (err) => {
      if (err) {
        alert(err.message);
      }
    });
    this.subscriber.on("videoDisabled", function (event) {
      console.warn(event);
      // TODO: handle quality message
    });
    this.session.on('signal', event =>{
      if (event.type.endsWith("permissionsDenied")) {
        // TODO: handle permissions message
        // if (ots.hasPermission('ManageStreams')) // es el medico
        console.log(event)
      }
    });
  }

  captureSnapshot = () => this.ots.submitImgData(this.subscriber.getImgData()).then(r => {
    if (r) this.ots.playAudio('capture');
  });

  startStetho() {
    this.session.signal({
      type: 'moderate_startStetho',
      to: this.stream.connection
    }, err=>err&&console.error(err));
    (<any>window).location.href = "stethoscope:master?room="+this.ots.sessionData.Session.Id;
  }

  toggleAudio() {
    // let volume = this.getSubscriberVolume();
    // if(volume == 0) this.subscriber.setAudioVolume(100);
    // else this.subscriber.setAudioVolume(0);
    this.session.signal({
      type: 'moderate_toggleAudioStream',
      to: this.stream.connection
    }, err=>err&&console.error(err));
  }

  // getSubscriberVolume() {
  //   return this.subscriber.stream.hasAudio;
  //   if(this.subscriber) return this.subscriber.getAudioVolume();
  //   return 0;
  // }

  changeParticipantDevices() {
    this.session.signal({
      type: 'fetch_deviceList',
      to: this.stream.connection
    }, err=>err&&console.error(err));
  }

  cycleParticipantVideo() {
    this.session.signal({
      type: 'moderate_cycleVideoStream',
      to: this.stream.connection
    }, err=>err&&console.error(err));
  }

  cycleParticipantAudio() {
    this.session.signal({
      type: 'moderate_cycleAudioStream',
      to: this.stream.connection
    }, err=>err&&console.error(err));
  }

}
