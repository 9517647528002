import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-deviceselector',
  templateUrl: './deviceselector.component.html',
  styleUrls: ['./deviceselector.component.css']
})
export class DeviceselectorComponent {

  constructor(
    public dialogRef: MatDialogRef<DeviceselectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
  }

  cancel = () => this.dialogRef.close(null);
  save = () => this.dialogRef.close(this.data.actual);

/*   public static Open(matDialogInstance: MatDialog, data: any, cb: Function): MatDialogRef<DeviceselectorComponent> {
    let modalDialog = matDialogInstance.open(DeviceselectorComponent, {
      disableClose: false,
      id: "deviceselect-modal-component",
      height: "300px",
      width: "450px",
      data: data
    });

    modalDialog.afterClosed().subscribe(res => cb(res));
    return modalDialog;
  } */

}
