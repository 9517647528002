<div [ngClass]="{'publishing': publishingVideo && !publishingScreen, 'publishingVideo': publishingVideo && publishingScreen, 'hidden': !streamOriginal}" 
class="self-video-preview" #publisherVideoDiv></div>
<div [ngClass]="{'publishing': publishingVideo && !publishingScreen, 'publishingVideo': publishingVideo && publishingScreen, 'hidden': streamOriginal}" class="self-video-preview"
    #publisherCanvasDiv>
    <canvas #canvasFiltro id="canvas-filtro"></canvas>
</div>

<div [ngClass]="{'publishing': !publishingVideo && publishingScreen, 'publishingScreen': publishingVideo && publishingScreen}"
    #publisherScreenDiv></div>
<ng-container *ngIf="publishingVideo">
    <div class="publisher-controls {{ots.userData.role}}">
        <button (click)="toggleVideo()" *ngIf="publisherVideo.stream !== undefined && publisherVideo.stream !== null && publisherVideo.stream.hasVideo; else elseVideo" class="controls-button">
            <fa-icon [icon]="faVideo"></fa-icon>
        </button>
        <ng-template #elseVideo>
            <button (click)="toggleVideo()" class="btn-red controls-button "><fa-icon [icon]="faVideoSlash"></fa-icon></button>
        </ng-template>
        <button (click)="toggleAudio()" *ngIf="publisherVideo.stream !== undefined && publisherVideo.stream !== null && publisherVideo.stream.hasAudio; else elseAudio" class="controls-button">
            <fa-icon [icon]="faMicrophone"> </fa-icon>
        </button>
        <ng-template #elseAudio>
            <button (click)="toggleAudio()" class="controls-button btn-red"> <fa-icon [icon]="faMicrophoneSlash"></fa-icon></button>
        </ng-template>
        <button (click)="screenSharing()"  class="controls-button"  title="Compartir pantalla">
            <span class="material-icons">
                cast
            </span>
        </button>

        <button (click)="chatView()"  class="controls-button"  title="Chat">
            <span class="material-icons">
                comment
            </span>
        </button>

        <button (click)="disconnectAll()" class="controls-button btn-red btn-hangup">
            <fa-icon [icon]="faPhoneSlash"></fa-icon>
        </button>
    </div>
    <hr style="display:none">
</ng-container>