import { Component, OnInit, ChangeDetectorRef, Inject, HostListener, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OpentokService } from '../opentok.service';
import { DOCUMENT } from '@angular/common';
import { faCompress } from '@fortawesome/free-solid-svg-icons';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import * as OT from '@opentok/client';
import { Subscription } from 'rxjs';
import { DatashareService } from '../services/datashare.service';

@Component({
  selector: 'app-root',
  templateUrl: './videoapp.component.html',
  styleUrls: ['./videoapp.component.css'],
  providers: [ OpentokService ]
})
export class VideoAppComponent implements OnInit {
  view: 'loading'|'streaming'|'watch'|'failed' = 'loading';
  doPublish = false;
  session: OT.Session;
  streams: {strm: OT.Stream, data:any, role:string}[] = [];
  connections: {conn: OT.Connection, name: string}[] = [];
  elem;
  fullscreenOn = false;
  faCompress = faCompress;
  faExpand = faExpand;
  screen:boolean = false;

  // Chat
  chatIsView = false;
  unreadMessages = 0;

  // Subscriptions
  subscriptions: Subscription[] = [];

  desconectado = false;

  get currentState () {
    return (<any>this.session).currentState;
  }


  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  constructor(@Inject(DOCUMENT) private document: any, 
  private route: ActivatedRoute, 
  private router: Router,
  private changeDetectorRef: ChangeDetectorRef, 
  public ots: OpentokService, 
  private data: DatashareService,
  private zone: NgZone) { }


  ngOnInit () {
    // Set the listeners for variables
    this.listeners();

    this.elem = document.documentElement;
    this.view = 'loading';
    let sess = this.route.snapshot.params.sess||null;
    if (sess) {
      this.ots.initSession(sess).then((session: OT.Session) => {
        this.doPublish = this.ots.userConfig.AutostartPublisher;
        
        if (this.ots.userConfig.MustSendLocation) {
          navigator.geolocation.getCurrentPosition(p=> this.ots.submitLocationData({
            Lat: p.coords.latitude,
            Long: p.coords.longitude,
            Accuracy: p.coords.accuracy
          }), err => console.error('Error while fetching location', err));
        }

        this.handleNameModifications(() => {
          
          this.session = session;
          this.session.on('streamCreated', (event) => {
            let data = {
              strm: event.stream,
              data: JSON.parse(event.stream.connection.data),
              role: 'unspecified'
            };
            if (data.data.Role) data.role = data.data.Role;
            // Si el stream es de pantalla
            if (data.strm.videoType === 'screen'){
              this.screen = true;
            }
            this.streams.push(data);
            this.ots.playAudio('connected');
            this.changeDetectorRef.detectChanges();
          });
          this.session.on('streamDestroyed', (event) => {
            let idx = -1;
            this.streams.forEach(st => {
              if (st.strm == event.stream)
                idx = this.streams.indexOf(st);
                // Para compartir pantalla
                if (st.strm.videoType === 'screen'){
                  this.screen = false;
                }
            })
            if (idx > -1) {
              this.streams.splice(idx, 1);
              this.changeDetectorRef.detectChanges();
              this.ots.playAudio('disconnected');
            }
          });
          this.session.on('signal', (event) => {
            if (event.type.endsWith('newConnection')) {
              this.connections.push({conn:event.from, name: JSON.parse(event.data).userName});
              console.log(this.connections);
            }
          });
          this.session.on('connectionCreated', (event) => {
            this.session.signal({
              to: event.connection,
              type: 'newConnection',
              data: JSON.stringify({
                userName: this.ots.sessionData.Session.UserData.name,
                role: this.ots.sessionData.Session.Permissions.TokboxRole
              })
            }, err=>err&&console.error(err));
          });
          this.session.on('connectionDestroyed', (event) => {
            let conn;
            this.connections.forEach(item => {if (event.connection==item.conn) conn = item});
            const idx = this.connections.indexOf(conn);
            if (idx > -1) this.connections.splice(idx, 1);
            console.log(this.connections);
          });

          this.session.on("sessionDisconnected", (event) => {

            
            // TODO: Handle message
            //alert("The session disconnected. " + event.reason);
            if(event.reason === 'forceDisconnected') {
              this.zone.run(() => {
                this.desconectado = true;
              });
            }
          });
        });
      })
      .then(() => this.ots.connect().then((test)=> {
        console.warn(this.ots.sessionData);

      }))
      .catch((err) => {
        this.view = 'failed';
        console.error(err);
        alert('Unable to connect. Make sure you have updated the config.ts file with your OpenTok details.');
      });
    } else {
      this.view = 'failed';
      alert('Session not provided (participantHash).');
    }
  }

  ngOnDestroy() {
    // Unsubscribe all
    this.subscriptions.forEach(sub => { sub.unsubscribe() })
  }

  listeners() {
    // Chat listeners
    this.subscriptions.push(this.data.shareChatShowing.subscribe(status => {
      this.chatIsView = status; }))
    this.subscriptions.push(this.data.shareUnreadMessages.subscribe(n => this.unreadMessages = n))
  }

  handleNameModifications(cb: Function) {
    if (this.ots.userConfig.MustChangeName) {
      let name = prompt("Por favor introduzca su nombre", "Invitado");
      this.ots.sessionData.Session.UserData.name = name;
    }
    if (cb) cb();
  }

  getSpectatorNames(separator=", ",lastseparator=" y ") {
    let spectators = [];
    this.connections.forEach(conn => {
      let found = false;
      this.streams.forEach(stream => {
        if (stream.strm.connection==conn.conn) found = true;
      });
      if (!found && conn.conn != this.session.connection) spectators.push(conn.name);
    });

    if (spectators.length!=0) {
      let last = spectators[spectators.length-1];
      spectators.splice(spectators.length-1,1);

      return (spectators.length)?spectators.join(separator)+lastseparator+last:last;
    } else return ''
  }

  startArchive = () => this.ots.startArchive();
  stopArchive = () => this.ots.stopArchive();

  toggleFullscreen(){

    if(this.checkFullscreen()) this.closeFullscreen();
    else this.openFullscreen();

  }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }

  /* Close fullscreen */
  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }

  checkFullscreen() {

    if(!this.document.fullscreenElement && !this.document.mozFullScreenElement && !this.document.webkitFullscreenElement && !this.document.msFullscreenElement) return false;
    else return true;

  }

  gridStyleCache = {};
  fetchGridStyle(index) {
    if (this.gridStyleCache[index+'-'+this.streams.length]!=undefined) {
      return this.gridStyleCache[index+'-'+this.streams.length];
    } else {
      let style = '';
      let nc = this.streams.length;
      let nr = 1;
      if (nc>3) {
        nc = 3;
        nr = 2;
      }
      let ri = Math.trunc(index / nc);
      let ci = index-(ri*nc);

      // Uncomment to debug
      // console.log('Index '+index, nc, ci, nr, ri);

      style += 'left: calc(100% / '+nc+' * '+ci+'); width: calc(100% / '+nc+');';
      style += 'top: calc(100% / '+nr+' * '+ri+'); height: calc(100% / '+nr+');';

      return style;
    }
  }

}
