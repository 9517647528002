import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
//import { OpentokService } from './opentok.service';

@Injectable({
    providedIn: 'root'
})
export class FileUploadService {

    constructor(private http: HttpClient,
                /* private opentok: OpentokService */) {
                    // Constructor
                 }

    URL_UPLOAD = 'https://api.ticmeet.ticsmart.eu/uploadfile';
    URL_SAVE_MESSAGES = '';
    

    /**
     * Sube un fichero a la API de ficheros de EH
     * @param file 
     * @param hash 
     * @param tipo 
     * @returns 
     */
    sendFile(file, hash, id_user/* tipo = '0' */) {

        const formData: FormData = new FormData();

        formData.append('fichero', file, file.name);
        formData.append('hash', hash);
        formData.append('id_user', id_user);

        return this.http.post(this.URL_UPLOAD, formData);

    }

    /**
     * Guarda una conversación de chat
     */
    /* saveChatMessage(message) {
        // Solo guardar aquellos mensajes generados por nosotros, para evitar duplicidad
        if (message.username === this.opentok.userData.username) {
            let sid = this.opentok.sessionData.Session.Hash
            const formData: FormData = new FormData();

            formData.append('sid', sid);
            formData.append('message', message);

            return this.http.post(this.URL_SAVE_MESSAGES, formData)
        }
        
        return false;
    } */

}
