<!-- cdkDrag -->
<div class="chat-div" [ngClass]="{'hidden': !chatIsView}"  >

    <div class="chat-top-bar" cdkDragHandle>
        <h1>Chat</h1>
        <button class="close-button" title="Cerrar chat" (click)="disableChat()" matTooltip="Cerrar chat">
          <i class="material-icons" aria-hidden="true">close</i>
        </button>
    </div>
  
    <div #scroll class="scroll" [ngClass]="{'welcome': messages.length === 0}" aria-live="assertive"> 
        <div class="welcome" *ngIf="messages.length === 0">
          <p>{{p1}}</p>
          <p>{{p2}}</p>
        </div>
  
        <div class="message-box"  *ngFor="let m of messages" [class]="m.role" [ngClass]="{'chat-left': !m.sameUser, 'chat-right': m.sameUser}" >
  
              <!-- File message -->
              <div class="file message" role="link" *ngIf="m.type === 'file'" aria-hidden="false" [attr.aria-label]="m.username + 'ha enviado un fichero'">
                <span class="material-icons" aria-hidden="true"> file_present </span>
                <span class="link"><a [href]="m.signedUrl" target="_blank">{{m.name}}.{{m.extension}}</a></span>
              </div>
  
              <!-- Image message -->
              <div class="image message" *ngIf="m.type === 'image'" >
                <a [href]="m.signedUrl">
                  <img [alt]="'Imagen de enviada por ' + m.username " [attr.aria-label]="m.username + ' ha enviado una imagen'" [src]="m.signedUrl"/>
                </a>
              </div>
  
              <!-- Normal message -->
              <span class="message" *ngIf="m.msg.length > 0" role="menuitem"  aria-hidden="false" [attr.aria-label]="m.username + ' escribió: ' + m.msg + '. A las ' + m.date ">{{m.msg}}</span>
  
              <!-- Date -->
              <span class="date" aria-hidden="true">{{m.username}}, {{m.date}}</span>
  
        </div>
    </div>
  
    <div id="dropzoneElement" class="textarea-div">
      <div class="message-area">
  
        <input placeholder="Escribe tu mensaje" (keyup.enter)="prepareMessage(textareaChat)" #textareaChat type="text" autocomplete="off" />
        <button matTooltip="Enviar mensaje" matSuffix mat-icon-button aria-label="Enviar mensaje">
          <i class="material-icons" aria-hidden="true">send</i>
        </button>
      </div>
    </div>

  </div>
  