import { Component, ElementRef, AfterViewInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OpentokService } from '../../../opentok.service';
import { faVideo, faSyncAlt, faVideoSlash, faMicrophone, faMicrophoneSlash, faPhoneSlash, faCircle, faStop, faCog, faBroadcastTower } from '@fortawesome/free-solid-svg-icons';
import { DeviceselectorComponent } from '../deviceselector/deviceselector.component';
import { DatashareService } from '../../../services/datashare.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-publisher', 
  templateUrl: './publisher.component.html',
  styleUrls: ['./publisher.component.css']
})

export class PublisherComponent implements AfterViewInit, OnDestroy {
  @ViewChild('publisherVideoDiv', {static: true}) publisherVideoDiv: ElementRef;
  @ViewChild('publisherScreenDiv', { static: true }) publisherScreenDiv: ElementRef;
  @Input() session: OT.Session;

  //video chat
  publisherVideo: OT.Publisher;
  publishingVideo: Boolean;
 
  //screen chat
  publisherScreen: OT.Publisher;
  publishingScreen: Boolean;
  screenCap: Boolean;

  // Chat
  chatIsView = false;
  unreadMessages = 0;

  faVideo = faVideo;
  faVideoSlash = faVideoSlash;
  faMicrophone = faMicrophone;
  faMicrophoneSlash = faMicrophoneSlash;
  faPhoneSlash = faPhoneSlash;
  faCircle = faCircle;
  faSyncAlt = faSyncAlt;
  faStop = faStop;
  faCog = faCog;
  faBroadcastTower = faBroadcastTower;

  streamOriginal: Boolean = true;

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(public ots: OpentokService, public matDialog: MatDialog, private data: DatashareService) {
    this.publishingVideo = false;
    this.publishingScreen = false;
    this.screenCap = false;
  }

  ngOnInit(): void {

    // Set the listeners for variables
    this.listeners();
  }

  ngAfterViewInit() {
    const OT = this.ots.getOT();
    this.publisherVideo = OT.initPublisher(this.publisherVideoDiv.nativeElement, {insertMode: 'append', fitMode: 'contain', style: {buttonDisplayMode: 'off'}});

    // if (!ots.hasPermission('ManageStreams'))
    if (this.ots.userConfig.MustSendSignalIfPermissionsDenied) {
      this.publisherVideo.on('accessDenied', () => {
        this.session.signal({
          type: 'permissionsDenied',
          // to: event.from, como no conocemos el stram del médico lo enviamos a todos
          data: this.publisherVideo.id+""
        }, err=>err&&console.error(err));
      });
    }

    if (this.session) {
      if (this.session['isConnected']()) {
        this.publish();
      }
      this.session.on('sessionConnected', () => this.publish());
    }
  }

  ngOnDestroy(): void {
    this.session.unpublish(this.publisherVideo);
    this.session.unpublish(this.publisherScreen);

    // Unsubscribe all
    this.subscriptions.forEach(sub => { sub.unsubscribe() })
  }

  listeners() {
    // Chat listeners
    this.subscriptions.push(this.data.shareChatShowing.subscribe(status => {
      this.chatIsView = status; 
    }))
    this.subscriptions.push(this.data.shareUnreadMessages.subscribe(n => this.unreadMessages = n))
  }

  publish() {
    this.session.publish(this.publisherVideo, (err) => {
      if (err) {
        alert(err.message);
      } else {
        this.publishingVideo = true;

        this.selectDevices();

        this.publisherVideo.publishVideo(this.ots.userConfig.AutostartPublisherVideo);
        this.publisherVideo.publishAudio(this.ots.userConfig.AutostartPublisherAudio);

        this.session.on('signal', event => {
          if (event.type.endsWith("fetch_deviceList")) {
            OT.getDevices((err, devices) => {
              if (!err) {
                this.session.signal({
                  type: 'fetch_deviceListResponse',
                  to: event.from,
                  data: JSON.stringify(this.generateDevicesData(devices))
                }, err=>err&&console.error(err));
              }
            });
          }
          if (event.type.endsWith("fetch_deviceListResponse")) {
            /* DeviceselectorComponent.Open(this.matDialog, JSON.parse(event.data), res=>{
              this.session.signal({
                type: 'moderate_setDevices',
                to: event.from,
                data: JSON.stringify(res)
              }, err=>err&&console.error(err));
            }); */
          }
          if (event.type.endsWith("moderate_setDevices")) {
            this.setDevices(JSON.parse(event.data));
          }
          if (event.type.endsWith("moderate_cycleVideoStream")) {
            this.cycleVideo();
          }
          if (event.type.endsWith("moderate_cycleAudioStream")) {
            this.cycleAudio();
          }
          if (event.type.endsWith("moderate_toggleAudioStream")) {
            this.toggleAudio();
          }
          if (event.type.endsWith("moderate_startStetho")) {
            (<any>window).location.href = "stethoscope:slave?room="+this.ots.sessionData.Session.Id;
          }
          if (event.type.endsWith("disconnectAll")){
 
            /* if(this.ots.getRole() === 'agenda_applicant') setTimeout(() => {this.hangUp();},200)
            else { */

              this.hangUp();
            //}
          }
        });
      }
    });
  }

  toggleAudio() {
    this.publisherVideo.publishAudio(!this.publisherVideo.stream.hasAudio)
  }
  toggleVideo() {
    this.publisherVideo.publishVideo(!this.publisherVideo.stream.hasVideo)
  }
  cycleVideo = () => this.publisherVideo.cycleVideo();
  cycleAudio() {
    // Cycling through microphone inputs
    OT.getDevices((err, devices) => {
      if (!err) {
        let currentIndex = 0;
        let audioInputs = devices.filter((device) => device.kind === 'audioInput');
        // Find the right starting index for cycleMicrophone
        audioInputs.forEach((device, idx) => {
          if (device.label === this.publisherVideo.getAudioSource().label) {
            currentIndex = idx;
          }
        });

        let device = audioInputs[(currentIndex+1) % audioInputs.length];
        this.publisherVideo.setAudioSource(device.deviceId);
        console.log('Changed audio source ['+(currentIndex+1)+"/"+audioInputs.length+"]", device.label, device.deviceId);
      } else {
        console.error(err);
      }
    });

  }

  generateDevicesData(devices) {
    return {
      inputs: {
        audio: devices.filter((device) => device.kind === 'audioInput'),
        video: devices.filter((device) => device.kind === 'videoInput')
      },
      actual: {
        audio: null,
        video: null
      }
    };
  }

  selectDevices() {
    OT.getDevices((err, devices) => {
      if (!err) {
        //DeviceselectorComponent.Open(this.matDialog, this.generateDevicesData(devices), res=>this.setDevices(res));
      } else {
        console.error(err);
      }
    });
  }

  setDevices(res) {
    if (res) {
      console.log('Changing devices', res);
      this.publisherVideo.setAudioSource(res.audio);
      this.setVideoSource(res.video);
    }
  }

  async setVideoSource(video:string) {
    let lastDevice='';
    for (let i=0; i<10&&lastDevice!=video; i++) {
      lastDevice = (await this.publisherVideo.cycleVideo()).deviceId;
    }
  }

  hangUp(){
    let sess = this.session;
    this.session.on("sessionDisconnected", function(event){
      console.log(sess);

    });
    this.session.disconnect();


  }

  screenSharing() {

    if (!this.publishingScreen) {

      this.publishingScreen = true;

      this.publisherScreen = OT.initPublisher(this.publisherScreenDiv.nativeElement, { videoSource: "screen", publishAudio: false, insertMode: 'append', fitMode: 'contain', style: { buttonDisplayMode: 'off' } });
      
      this.session.publish(this.publisherScreen, (err) => {

        console.log('err', err);
        if (err.name === 'OT_USER_MEDIA_ACCESS_DENIED'){
          this.publishingScreen = false;
        }
      });

    } else {
      this.publishingScreen = false;
      this.publisherScreen.destroy();
    }
  }

  /**
   * Conmuta el estado de del chat
   *
   */

  chatView() {
    this.chatIsView = !this.chatIsView;
    this.data.nextChatShowing(this.chatIsView);
  }

  disconnectAll() {

    
    // Hacemos petición para deshabilitar la sesión. Tanto si es exitoso como si no, se deshabilita
    this.ots.disableSession().then(() => {
      this.sendDisconnectAll();
    }).catch((err => {
      
      this.sendDisconnectAll();
    }
      ));
    
    //this.hangUp();
  }

  sendDisconnectAll() {
    this.ots.session.signal({
      type: 'disconnectAll',
      data: JSON.stringify({
        userName: this.ots.sessionData.Session.UserData.name,
        id: this.ots.sessionData.Session.UserData.id,
        role: this.ots.sessionData.Session.Permissions.TokboxRole
      })
    }, err => err && console.log(err));
  }
}
