// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  SAMPLE_SERVER_BASE_URL: 'https://opentok-web-samples-backend.herokuapp.com',
  API_VIDEO_SERVER: 'https://api.ticmeetold.ticsmart.eu',
  API_KEY: '',
  SESSION_ID: '',
  TOKEN: 'c893bad68927b457dbed39460e6afd62',
};
